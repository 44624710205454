import Http from "@/axios";

export const BrandsRequest = {
    brands() {
        return new Promise((resolve, reject) => {
            Http.get('/store/brands').then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    createBrand(data) {
        return new Promise((resolve, reject) => {
            Http.post('/admin/store/brands', data).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    updateBrand(id, data) {
        return new Promise((resolve, reject) => {
            Http.put(`/admin/store/brands/${id}`, data).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },
}