import Http from "@/axios";

export const NichesRequest = {
    niches() {
        return new Promise((resolve, reject) => {
            Http.get('/store/niches').then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    createNiche(data) {
        return new Promise((resolve, reject) => {
            Http.post('/admin/store/niches', data).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    updateNiche(id, data) {
        return new Promise((resolve, reject) => {
            Http.put(`/admin/store/niches/${id}`, data).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },
}