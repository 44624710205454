<script>
import ToastComponent from "@/components/common/ToastComponent"
export default {
  name: 'App',
  components: {ToastComponent}
}
</script>

<template>
  <div class="block w-full overflow-hidden">
    <ToastComponent/>

    <div class="flex flex-row h-screen">

      <!-- Sidebar Block -->
      <router-view name="sidebar"/>

      <!-- App Container -->
      <router-view/>
    </div>
  </div>
</template>
