import Http from "@/axios";

export const CategoriesRequest = {
    categories(params) {
        return new Promise((resolve, reject) => {
            Http.get(`admin/store/categories?size=${params.size}&page=${params.page}&sort=desc`).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    categorySubcategories(id) {
        return new Promise((resolve, reject) => {
            Http.get(`/store/categories/${id}/sub`).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    category(id) {
        return new Promise((resolve, reject) => {
            Http.get(`/store/categories/${id}`).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    createCategory(data) {
        return new Promise((resolve, reject) => {
            Http.post(`/admin/store/categories`, data).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    createSubCategory(data) {
        return new Promise((resolve, reject) => {
            Http.post(`/admin/store/subCategories`, data).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    updateCategory(id, data) {
        return new Promise((resolve, reject) => {
            Http.put(`/admin/store/categories/${id}`, data).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    updateSubCategory(id, data) {
        return new Promise((resolve, reject) => {
            Http.put(`/admin/store/subCategories/${id}`, data).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },
}