//  Pages
const WelcomePage = () => import(/* webpackChunkName: "WelcomePage" */ '@/pages/WelcomePage.vue')
const HomePage = () => import(/* webpackChunkName: "HomePage" */ '@/pages/HomePage.vue')
const NotFoundPage = () => import(/* webpackChunkName: "HomePage" */ '@/pages/NotFoundPage')

//  Components
import { Sidebar } from "@/use/components";

export const AppRoutes = [
    {
        path: '/',
        name: 'WelcomePage',
        components: { default: WelcomePage, sidebar: Sidebar},
        meta: {
            authenticated: true,
            PageTitle: "Welcome"
        }
    },

    {
        path: '/home',
        name: 'HomePage',
        components: {default: HomePage /*BlockName: NameComponent*/},
        meta: {
            authenticated: true,
            PageTitle: "Home"

        }
    },

    {
        path: "/404",
        alias: "/:pathMatch(.*)*",
        name: "NotFoundPage",
        components: { default: NotFoundPage },
        meta: {

            PageTitle: "Not Found",
        },
    }
]