import {createRouter, createWebHistory} from "vue-router";
// eslint-disable-next-line no-unused-vars
import store from "@/store";

// Routes Import
import {AppRoutes} from "@/pages/routes";
import {SecurityRoutes} from "@/services/security/routes";
import {StoresRoutes} from "@/services/stores/routes";
import {CategoriesRoutes} from "@/services/categories/routes";
import {NichesRoutes} from "@/services/niches/routes";
import {TypesRoutes} from "@/services/types/routes";
import {BrandsRoutes} from "@/services/brands/routes";
import {UsersRoutes} from "@/services/users/routes";
import {DepartmentsRoutes} from "@/services/departments/routes";
import {CommunitiesRoutes} from "@/services/catalogue/routes";

const AllRoutes = [
    //  Default App Routes
    ...AppRoutes,

    //  Services Routes
    ...SecurityRoutes,

    //  Stores routes
    ...StoresRoutes,

    //  Categories routes
    ...CategoriesRoutes,

    //  Niches routes
    ...NichesRoutes,

    //  Types routes
    ...TypesRoutes,

    //  Brands routes
    ...BrandsRoutes,

    //  Users routes
    ...UsersRoutes,

    // Departments routes
    ...DepartmentsRoutes,

    // Communities routes
    ...CommunitiesRoutes

];

const routes = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: AllRoutes,
});


routes.beforeEach((to, from, next) => {
    document.title = `${to.meta.PageTitle}`;
    if (to.meta.authenticated && !store.state.user.token) {
        next({name: "LoginPage"});
    } else if (store.state.user.token && to.meta.guest) {
        next({name: "HomePage"});
    } else {
        next();
    }
});

export default routes;
