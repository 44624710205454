let defaults = {
    type: 'success',
    time: 2000,
    text: '',
    delay: 0
}

export const Toast = {

    namespaced: true,

    state: {
        toast: null
    },

    getters: {
        toast(state){
            return state.toast
        }
    },

    mutations: {
        SET_TOAST: ( state, value ) =>{
            state.toast = value
        }
    },

    actions: {
        toast({ commit }, options ){
            options = { ...defaults, ...options}
            setTimeout(() => {
                commit('SET_TOAST', options)
                setTimeout(() => {
                    commit('SET_TOAST', null)
                }, options.time)
            }, options.delay)
        }
    }
}
