import {BrandsRequest} from "@/services/brands/requests";
import store from "@/store";

export const BrandsStore = {

    namespaced: true,

    state: {
        brands: [],
        loading: false,
    },

    getters: {
        brands(state){
            return state.brands
        },

        loading(state){
            return state.loading
        }
    },

    mutations: {
        SET_BRANDS: (state, value) => {
            state.brands = value
        },

        SET_LOADING: (state, value) => {
            state.loading = value
        }
    },

    actions: {
        getBrands({commit}){
            store.commit('SET_PAGE_LOADING', true)
            BrandsRequest.brands()
                .then((response) => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_BRANDS', response.data)
                })
                .catch(() => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_BRANDS', [])
                })
        },

        createBrand({commit, dispatch}, data){
            commit('SET_LOADING', true)
            BrandsRequest.createBrand(data)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_BRANDS', response.data)
                    dispatch('getBrands')
                    store.dispatch("Toast/toast", {text: 'Brand created'}).then()
                })
                .catch(() => {
                    commit('SET_LOADING', false)
                    commit('SET_BRANDS', [])
                })
        },

        updateBrand({commit, dispatch}, data){
            commit('SET_LOADING', true)
            BrandsRequest.updateBrand(data.id, data.form)
                .then(() => {
                    commit('SET_LOADING', false)
                    dispatch('getBrands')
                    store.dispatch("Toast/toast", {text: 'Brand updated'}).then()
                })
                .catch(() => {
                    commit('SET_LOADING', false)
                    commit('SET_BRANDS', [])
                })
        }
    }

}