import store from '@/store';

export default  {

  successAlertModal(text) {
    store.dispatch("Toast/toast", {
      text: text
    }).then()
  },

  errorAlertModal(text = 'Something went wrong') {
    store.dispatch("Toast/toast", {
      type: "error",
      text: text,
    }).then()
  },
}