import Http from "@/axios";

export const StoresRequest =  {
    stores(params){
        return new Promise(  (resolve, reject) => {
            Http.get(`/admin/stores?filter=${params.filter}&size=${params.size}&page=${params.page}`).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    store(id){
        return new Promise((resolve, reject) => {
            Http.get(`/admin/stores/${id}`).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    verifyStore(data){
        return new Promise((resolve, reject) => {
            Http.post(`/admin/stores/${data.id}/approveVerification`, data.form).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },
}