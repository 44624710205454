import Http from "@/axios";

export const UsersRequest = {
    users(params) {
        return new Promise((resolve, reject) => {
            Http.get(`admin/account/users?filter=${params.filter}&size=${params.size}&page=${params.page}`).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    user(id) {
        return new Promise((resolve, reject) => {
            Http.get(`admin/account/users/${id}`).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },
}