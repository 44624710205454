const LoginPage = () => import(/* webpackChunkName: "LoginPage" */ "@/services/security/pages/LoginPage")

export const SecurityRoutes = [
  {
    path: "/login",
    name: "LoginPage",
    components: { default: LoginPage },
    meta: {
      guest: true,
      PageTitle: process.env.VUE_APP_NAME + " - Login",
    },
  }
];
