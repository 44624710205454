import store from "@/store"
import {SecurityRequest} from "@/services/security/requests"

export const SecurityStore = {

    namespaced: true,

    state: {
        token: null,
        loading: false,
    },

    getters: {
        token(state){
            return state.token
        },

        loading(state){
            return state.loading
        }
    },

    mutations: {
        SET_TOKEN: (state, value) => {
            state.token = value
        },

        SET_LOADING: (state, value) => {
            state.loading = value
        }
    },

    actions: {

        login({commit}, credentials) {
            commit('SET_LOADING', true)
            SecurityRequest.login(credentials)
                .then((response) => {
                    commit('SET_LOADING', false)
                    store.dispatch('setUser', response)
                    store.dispatch('Toast/toast', {
                        type: 'success',
                        text: "Your are login"
                    })
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    if(!error.success)
                    {
                        store.dispatch('Toast/toast', {
                            type: 'error',
                            text: "Wrong credentials"
                        })
                    }
                })
        },

        logout(){
            SecurityRequest.logout()
                .then(() => {
                    store.dispatch('resetAuth')
                })
        }
    }
}