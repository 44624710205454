import Http from "@/axios";

export const CommunitiesRequest = {
    communities(params) {
        return new Promise((resolve, reject) => {
            Http.get(`admin/store/communities`, {
                params: {...params}
            }).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    community(id) {
        return new Promise((resolve, reject) => {
            Http.get(`admin/store/communities/${id}`).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    createCommunity(data) {
        return new Promise((resolve, reject) => {
            Http.post(`admin/store/communities`, data).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },


    updateCommunity(id, data) {
        return new Promise((resolve, reject) => {
            Http.put(`admin/store/communities/${id}`, data).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },


    deleteCommunity(id) {
        return new Promise((resolve, reject) => {
            Http.delete(`admin/store/communities/${id}`).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

}