import axios from 'axios'

const Http = axios.create({
    baseURL: process.env.VUE_APP_URL,
    headers: {'Access-Control-Allow-Origin': '*'}
})

Http.interceptors.request.use( config => {
    let token = localStorage.getItem('app-auth-token') || null
    if(token){
        config.headers.Authorization = `Bearer ${token}`
        return config
    }else{
        return config
    }
})

export default Http