const CategoryListPage = () => import(/* webpackChunkName: "CategoryListPage" */ "@/services/categories/pages/CategoryListPage")
const CategoryPage = () => import(/* webpackChunkName: "CategoryPage" */ "@/services/categories/pages/CategoryPage")
const SidebarComponent = () => import(/* webpackChunkName: "SidebarComponent" */ "@/components/common/SidebarComponent")

export const CategoriesRoutes = [
  {
    path: "/categories",
    name: "CategoryListPage",
    components: { default: CategoryListPage, sidebar: SidebarComponent },
    meta: {
      authenticated: true,
      PageTitle: process.env.VUE_APP_NAME + " - Categories list",
    },
  },

  {
    path: "/category/:id",
    name: "CategoryPage",
    components: { default: CategoryPage, sidebar: SidebarComponent },
    meta: {
      authenticated: true,
      PageTitle: process.env.VUE_APP_NAME + " - Category info",
    },
  },
];