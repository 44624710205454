import store from "@/store"
//import router from "@/routes";
import {StoresRequest} from "@/services/stores/requests";

export const StoresStore = {

    namespaced: true,

    state: {
        stores: [],
        store: null,
        pageable: null,
        loading: false,
    },

    getters: {
        stores(state){
            return state.stores
        },

        store(state){
            return state.store
        },

        pageable(state){
            return state.pageable
        },

        loading(state){
            return state.loading
        }
    },

    mutations: {
        SET_STORES: (state, value) => {
            state.stores = value.data
            state.pageable = value.pageable
        },

        SET_STORE: (state, value) => {
            state.store = value
        },

        SET_LOADING: (state, value) => {
            state.loading = value
        },
    },

    actions: {

        getStores({commit}, params){
            store.commit('SET_PAGE_LOADING', true)
            StoresRequest.stores(params)
                .then((response) => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_STORES', response.data)
                })
                .catch(() => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_STORES', [])
                })
        },

        getStore({commit}, id){
            store.commit('SET_PAGE_LOADING', true)
            StoresRequest.store(id)
                .then((response) => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_STORE', response.data)
                })
                .catch(() => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_STORE', null)
                })
        },


        verifyStore({commit, dispatch}, data){
            commit('SET_LOADING', true)
            StoresRequest.verifyStore(data)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_STORE', response.data)
                    dispatch('getStore', response.data.id)
                    store.dispatch('Toast/toast', { text: 'Store verified'})
                })
                .catch(() => {
                    commit('SET_LOADING', false)
                    commit('SET_STORE', null)
                })
        }
    }
}