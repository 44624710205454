const UserListPage = () => import(/* webpackChunkName: "UserListPage" */ "@/services/users/pages/UserListPage")
const UserPage = () => import(/* webpackChunkName: "UserPage" */ "@/services/users/pages/UserPage")
const SidebarComponent = () => import(/* webpackChunkName: "SidebarComponent" */ "@/components/common/SidebarComponent")

export const UsersRoutes = [
  {
    path: "/users",
    name: "UserListPage",
    components: { default: UserListPage, sidebar: SidebarComponent },
    meta: {
      authenticated: true,
      PageTitle: process.env.VUE_APP_NAME + " - Users list",
    },
  },

  {
    path: "/user/:id",
    name: "UserPage",
    components: { default: UserPage, sidebar: SidebarComponent },
    meta: {
      authenticated: true,
      PageTitle: process.env.VUE_APP_NAME + " - User detail",
    },
  }
]