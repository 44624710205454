const DepartmentsListPage = () => import(/* webpackChunkName: "DepartmentsListPage" */ "@/services/departments/pages/DepartmentsListPage")
const SidebarComponent = () => import(/* webpackChunkName: "SidebarComponent" */ "@/components/common/SidebarComponent")

export const DepartmentsRoutes = [
  {
    path: "/departments",
    name: "DepartmentsListPage",
    components: { default: DepartmentsListPage, sidebar: SidebarComponent },
    meta: {
      authenticated: true,
      PageTitle: process.env.VUE_APP_NAME + " - Departments",
    },
  }
];