import Http from "@/axios";

export const TypesRequest = {
    types() {
        return new Promise((resolve, reject) => {
            Http.get('/store/types').then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    createType(data) {
        return new Promise((resolve, reject) => {
            Http.post('/admin/store/types', data).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    updateType(id, data) {
        return new Promise((resolve, reject) => {
            Http.put(`/admin/store/types/${id}`, data).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },
}