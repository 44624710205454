<script>

import {mapGetters} from "vuex";

export default {
  name: 'ToastComponent',

  computed: {
    ...mapGetters({
      toast: 'Toast/toast'
    })
  },
}

</script>

<template>
  <div class="flex justify-center fixed bottom-0 left-1/2 -translate-x-1/2">
    <transition name="toast">
      <div
        v-if="toast"
        :class=" toast.type === 'error' ? 'bg-red-500' : 'bg-teal-600' "
        class="text-white text-sm px-6 py-2 mb-[70px] sm:mb-6 shadow-sm rounded whitespace-nowrap"
      >
        {{ toast?.text }} .
      </div>
    </transition>
  </div>
</template>


