import store from "@/store";
import {DepartmentsRequest} from "@/services/departments/requests";
import handleDepartmentsRequestResponse from "@/services/departments/utils/handleDepartmentsRequestResponse";

export const DepartmentsStore = {

    namespaced: true,

    state: {
        departments: [],
        loading: false,
    },

    getters: {
        departments(state){
            return state.departments
        },

        loading(state){
            return state.loading
        }
    },

    mutations: {
        SET_DEPARTMENTS: (state, value) => {
            state.departments = value
        },

        SET_LOADING: (state, value) => {
            state.loading = value
        }
    },

    actions: {
        getDepartments({commit}){
            store.commit('SET_PAGE_LOADING', true)
            DepartmentsRequest.getDepartments()
                .then((response) => {
                    if(response.success){
                        store.commit('SET_PAGE_LOADING', false)
                        commit('SET_DEPARTMENTS', response.data)
                    }
                })
                .catch(() => {
                    store.commit('SET_PAGE_LOADING', false)
                })
        },

        createDepartment({commit}, data){
            commit('SET_LOADING', true)
            DepartmentsRequest.createDepartment(data)
                .then((response) => {
                    if(response.success){
                        commit('SET_LOADING', false)
                        commit('SET_DEPARTMENTS', response.data)
                        store.dispatch("DepartmentsStore/getDepartments").then()
                        handleDepartmentsRequestResponse.handleSuccessRequest(response, 'Department created')
                    }
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    handleDepartmentsRequestResponse.handleErrorRequest(error)
                })
        },

        updateDepartment({commit}, data){
            commit('SET_LOADING', true)
            DepartmentsRequest.updateDepartment(data.id, data.form)
                .then((response) => {
                    if(response.success){
                        commit('SET_LOADING', false)
                        store.dispatch("DepartmentsStore/getDepartments").then()
                        handleDepartmentsRequestResponse.handleSuccessRequest(response, 'Updated created')
                    }
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    handleDepartmentsRequestResponse.handleErrorRequest(error)
                })
        }
    }

}