import store from "@/store"
import {UsersRequest} from "@/services/users/requests";

export const UsersStore = {
    namespaced: true,

    state: {
        users: [],
        user: null,
        pageable: null,
        loading: false,
    },

    getters: {
        users(state){
            return state.users
        },

        user(state){
            return state.user
        },

        pageable(state){
            return state.pageable
        },

        loading(state){
            return state.loading
        }
    },

    mutations: {
        SET_USERS: (state, value) => {
            state.users = value.data
            state.pageable = value.pageable
        },

        SET_USER: (state, value) => {
            state.user = value
        },

        SET_LOADING: (state, value) => {
            state.loading = value
        },
    },

    actions: {
        getUsers({commit}, params){
            store.commit('SET_PAGE_LOADING', true)
            UsersRequest.users(params)
                .then((response) => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_USERS', response.data)
                })
                .catch(() => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_USERS', [])
                })
        },

        getUser({commit}, id){
            store.commit('SET_PAGE_LOADING', true)
            UsersRequest.user(id)
                .then((response) => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_USER', response.data)
                })
                .catch(() => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_USER', null)
                })
        },
    }
}