import {TypesRequest} from "@/services/types/requests";
import store from "@/store";

export const TypesStore = {

    namespaced: true,

    state: {
        types: [],
        loading: false,
    },

    getters: {
        types(state){
            return state.types
        },

        loading(state){
            return state.loading
        }
    },

    mutations: {
        SET_TYPES: (state, value) => {
            state.types = value
        },

        SET_LOADING: (state, value) => {
            state.loading = value
        }
    },

    actions: {
        getTypes({commit}){
            store.commit('SET_PAGE_LOADING', true)
            TypesRequest.types()
                .then((response) => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_TYPES', response.data)
                })
                .catch(() => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_TYPES', [])
                })
        },

        createType({commit, dispatch}, data){
            commit('SET_LOADING', true)
            TypesRequest.createType(data)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_TYPES', response.data)
                    dispatch('getTypes')
                    store.dispatch("Toast/toast", {text: 'Type created'}).then()
                })
                .catch(() => {
                    commit('SET_LOADING', false)
                    commit('SET_TYPES', [])
                })
        },

        updateType({commit, dispatch}, data){
            commit('SET_LOADING', true)
            TypesRequest.updateType(data.id, data.form)
                .then(() => {
                    commit('SET_LOADING', false)
                    dispatch('getTypes')
                    store.dispatch("Toast/toast", {text: 'Type updated'}).then()
                })
                .catch(() => {
                    commit('SET_LOADING', false)
                    commit('SET_TYPES', [])
                })
        }
    }

}