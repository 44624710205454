  
import helpers from "@/use/helpers";
import {ToolsRequest} from "@/services/catalogue/tools/requests";
import store from "@/store";


export const toolsStore = {
    namespaced: true,
    state: {
        tools: [],
        tool: null,
        loading: false,
    },

    getters: {
        tools(state) {
            return state.tools
        },

        tool(state) {
            return state.tool
        },

        loading(state) {
            return state.loading
        },

    },

    mutations: {
        SET_tools: (state, value) => {
            state.tools = value
        },

        SET_tool: (state, value) => {
            state.tool = value
        },

        PUSH_tool: (state, value) => {
            state.tools.unshift(value)
        },

        SET_LOADING: (state, value) => {
            state.loading = value
        },
    },

    actions: {
        getTools({commit}, params) {
            commit('SET_LOADING', true)
            ToolsRequest.tools(params)
                .then((response) => {
                    commit('SET_tools', response.data)
                })
                .catch(() => {
                    commit('SET_tools', [])
                    helpers.errorAlertModal('Something went wrong')
                }).finally(() => {
                commit('SET_LOADING', false)
            })
        },

        getTool({commit}, id) {
            store.commit('SET_PAGE_LOADING', true)
            ToolsRequest.tool(id)
                .then((response) => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_tool', response.data)
                })
                .catch(() => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_community', null)
                    helpers.errorAlertModal('Something went wrong')
                })
        },

        createTool({commit, dispatch}, data) {
            commit('SET_LOADING', true)
            ToolsRequest.createTools(data.form)
                .then(() => {
                    commit('SET_LOADING', false)
                    dispatch('getTools', data?.params)
                    helpers.successAlertModal('Tool updated')
                })
                .catch(() => {
                    commit('SET_LOADING', false)
                    commit('SET_tool', {})
                    helpers.errorAlertModal('Something went wrong')
                })
        },

        updateTool({commit, dispatch}, data) {
            commit('SET_LOADING', true)
            ToolsRequest.updateTools(data.form.id, data.form)
                .then((response) => {
                    if (response.success) {
                        commit('SET_LOADING', false)
                        dispatch('getTools', data.params)
                        helpers.successAlertModal('Tool updated')
                    }
                })
                .catch(() => {
                    commit('SET_LOADING', false)
                    helpers.errorAlertModal('Something went wrong')
                })
        },

        deleteTool({commit, dispatch}, data) {
            commit('SET_LOADING', true)
            ToolsRequest.deleteTools(data.id)
                .then((response) => {
                    if (response.success) {
                        commit('SET_LOADING', false)
                        helpers.successAlertModal('Tool deleted')
                    }
                })
                .catch(() => {
                    commit('SET_LOADING', false)
                    helpers.errorAlertModal('Something went wrong')
                }).finally(() => {
                dispatch('getTools', data.params)
            })
        }
    }
}