import {CategoriesRequest} from "@/services/categories/requests";
import store from "@/store"
import helpers from "@/use/helpers";

export const CategoriesStore = {

    namespaced: true,

    state: {
        categories: [],
        category: {
            subCategories:[]
        },
        categorySubcategories: [],
        pageable: null,
        loading: false,
    },

    getters: {
        categories(state){
            return state.categories
        },

        category(state){
            return state.category
        },

        categorySubcategories(state){
            return state.categorySubcategories
        },

        loading(state){
            return state.loading
        },

        pageable(state){
            return state.pageable
        },
    },

    mutations: {
        PUSH_SUB_CATEGORY:(state, value) => {
            state.category.subCategories.unshift(value)
        },

        SET_CATEGORIES: (state, value) => {
            state.categories = value.data
            state.pageable = value.pageable
        },

        SET_CATEGORY: (state, value) => {
            state.category = value
        },

        PUSH_CATEGORY: (state, value) => {
            state.categories.unshift(value)
        },

        SET_CATEGORY_SUBCATEGORIES: (state, value) => {
            state.categorySubcategories = value
        },

        SET_LOADING: (state, value) => {
            state.loading = value
        },
    },

    actions: {
        getCategories({commit}, params){
            store.commit('SET_PAGE_LOADING', true)
            CategoriesRequest.categories(params)
                .then((response) => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_CATEGORIES', response.data)
                })
                .catch(() => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_CATEGORIES', [])
                    helpers.errorAlertModal('Something went wrong')
                })
        },

        getCategory({commit}, id){
            store.commit('SET_PAGE_LOADING', true)
            CategoriesRequest.category(id)
                .then((response) => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_CATEGORY', response.data)
                })
                .catch(() => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_CATEGORY', null)
                    helpers.errorAlertModal('Something went wrong')
                })
        },

        getCategorySubcategories({commit}, id){
            store.commit('SET_PAGE_LOADING', true)
            CategoriesRequest.categorySubcategories(id)
                .then((response) => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_CATEGORY_SUBCATEGORIES', response.data)
                })
                .catch(() => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_CATEGORY_SUBCATEGORIES', [])
                    helpers.errorAlertModal('Something went wrong')
                })
        },

        createCategory({commit}, data){
            commit('SET_LOADING', true)
            CategoriesRequest.createCategory(data)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('PUSH_CATEGORY', response.data)
                    helpers.successAlertModal('Category updated')
                })
                .catch(() => {
                    commit('SET_LOADING', false)
                    commit('SET_CATEGORY', {})
                    helpers.errorAlertModal('Something went wrong')
                })
        },

        createSubCategory({commit}, data){
            commit('SET_LOADING', true)
            CategoriesRequest.createSubCategory(data)
                .then((response) => {
                    if(response.success){
                        commit('SET_LOADING', false)
                       commit('PUSH_SUB_CATEGORY', response.data)
                        helpers.successAlertModal('Subcategory created')
                    }
                })
                .catch(() => {
                    commit('SET_LOADING', false)
                    commit('SET_CATEGORY', {})
                    helpers.errorAlertModal('Something went wrong')
                })
        },


        updateCategory({commit, dispatch}, data){
            commit('SET_LOADING', true)
            CategoriesRequest.updateCategory(data.id, data.form)
                .then((response) => {
                    if(response.success){
                        commit('SET_LOADING', false)
                        dispatch('getCategories', data.params)
                        helpers.successAlertModal('Category updated')
                    }
                })
                .catch(() => {
                    commit('SET_LOADING', false)
                    helpers.errorAlertModal('Something went wrong')
                })
        },

        updateSubCategory({commit, dispatch}, data){
            commit('SET_LOADING', true)
            CategoriesRequest.updateSubCategory(data.id, data.form)
                .then((response) => {
                    if(response.success){
                        commit('SET_LOADING', false)
                        helpers.successAlertModal('Subcategory updated')
                        dispatch("getCategory", response.data.categoryId)
                    }
                })
                .catch(() => {
                    commit('SET_LOADING', false)
                    helpers.errorAlertModal('Something went wrong')
                })
        }
    }
}