import {NichesRequest} from "@/services/niches/requests";
import store from "@/store";

export const NichesStore = {

    namespaced: true,

    state: {
        niches: [],
        loading: false,
    },

    getters: {
        niches(state){
            return state.niches
        },

        loading(state){
            return state.loading
        }
    },

    mutations: {
        SET_NICHES: (state, value) => {
            state.niches = value
        },

        SET_LOADING: (state, value) => {
            state.loading = value
        }
    },

    actions: {
        getNiches({commit}){
            store.commit('SET_PAGE_LOADING', true)
            NichesRequest.niches()
                .then((response) => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_NICHES', response.data)
                })
                .catch(() => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_NICHES', [])
                })
        },

        createNiche({commit}, data){
            commit('SET_LOADING', true)
            NichesRequest.createNiche(data)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_NICHES', response.data)
                    store.dispatch("Toast/toast", {text: 'Niche created'}).then()
                })
                .catch(() => {
                    commit('SET_LOADING', false)
                    commit('SET_NICHES', [])
                })
        },

        updateNiche({commit, dispatch}, data){
            commit('SET_LOADING', true)
            NichesRequest.updateNiche(data.id, data.form)
                .then(() => {
                    commit('SET_LOADING', false)
                    dispatch('getNiches')
                    store.dispatch("Toast/toast", {text: 'Niche updated'}).then()
                })
                .catch(() => {
                    commit('SET_LOADING', false)
                    commit('SET_NICHES', [])
                })
        }
    }

}