import Http from "@/axios";

function makeGetRequest(endpoint) {
  return new Promise((resolve, reject) => {
    Http.get(`${endpoint}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => reject(error.response.data));
  });
}

function makePostRequest(endpoint, payload) {
  return new Promise((resolve, reject) => {
    Http.post(`${endpoint}`, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => reject(error.response.data));
  });
}

function makePutRequest(endpoint, payload) {
  return new Promise((resolve, reject) => {
    Http.put(`${endpoint}`, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => reject(error.response.data));
  });
}

function makePatchRequest(endpoint, payload) {
  return new Promise((resolve, reject) => {
    Http.patch(`${endpoint}`, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => reject(error.response.data));
  });
}

function makeDeleteRequest(endpoint) {
  return new Promise((resolve, reject) => {
    Http.delete(`${endpoint}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => reject(error.response.data));
  });
}

const API = {
  makeGetRequest,
  makePostRequest,
  makePutRequest,
  makePatchRequest,
  makeDeleteRequest,
};

export default API;
