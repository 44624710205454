import Http from "@/axios";

export const ToolsRequest = {
    tools(params) {
        return new Promise((resolve, reject) => {
            Http.get(`admin/store/tips`, {
                params: {...params}
            }).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    tool(id) {
        return new Promise((resolve, reject) => {
            Http.get(`admin/store/tips/${id}`).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    createTools(data) {
        return new Promise((resolve, reject) => {
            Http.post(`admin/store/tips`, data).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },


    updateTools(id, data) {
        return new Promise((resolve, reject) => {
            Http.put(`admin/store/tips/${id}`, data).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },


    deleteTools(id) {
        return new Promise((resolve, reject) => {
            Http.delete(`admin/store/tips/${id}`).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

}