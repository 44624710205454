import { CountriesRequest } from "@/services/countries/requests";
import handleCountriesRequestResponse from '@/services/countries/utils/handleCountriesRequestResponse';

export const CountriesStore = {
  namespaced: true,

  state: {
    countries: [],
    searchCountry: "",
    selectedCounty: null,
    defaultCountry: null,
    businessCountryId: null,
    countryCode: null
  },

  getters: {
    countries(state) {

      return state.countries;
    },

    searchCountry(state) {
      return state.searchCountry;
    },

    selectedCounty(state) {
      return state.defaultCountry
        ? state.defaultCountry
        : state.countries.filter(
            (country) => country.countryId === state.businessCountryId
          )[0];
    },

    countriesListFromSearchQuery(state) {
      return state.countries.filter((country) =>
        country.countryName
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(state.searchCountry.toLowerCase().replace(/\s+/g, ""))
      );
    },

  },

  mutations: {
    GET_COUNTRIES: (state, value) => {
      state.countries = value;
    },

    SET_SEARCH_COUNTRY: (state, query) => {
      state.searchCountry = query;
    },

    SET_SELECTED_COUNTRY: (state, country) => {
      state.defaultCountry = country;
    },

    GET_BUSINESS_COUNTRY: (state, countryId) => {
      state.businessCountryId = countryId;
    },

  },

  actions: {

    getBusinessCountry({ commit }, countryId) {
      commit("GET_BUSINESS_COUNTRY", countryId);
    },

    handleSearchCountry({ commit }, query) {
      commit("SET_SEARCH_COUNTRY", query);
    },

    handleSelectedCountry({ commit }, country) {
      commit("SET_SELECTED_COUNTRY", country);
    },

    getCountries({ commit }) {
      CountriesRequest.getCountries()
        .then((data) => {
          commit("GET_COUNTRIES", data)
          /*findUserLocation(data)*/
        })
        .catch((error) => {
          handleCountriesRequestResponse.handleErrorRequest(error.code)
        });
    },
  },
};
