import { createApp } from 'vue'
import App from './App.vue'
import router from '@/routes'
import store from '@/store'

//  App Style
import '@/assets/css/tailwind.css'

//  Manage Global Events
import mitt from "mitt"
const emitter = mitt()

const app = createApp(App)

app.provide('emitter', emitter)

if( store.state.user.token !== null ) {
    store.dispatch('getUser')
        .then(() => {
            app.use(store).use(router).mount('#app')
        })
}else{
    app.use(store).use(router).mount('#app')
}
