import helpers from "@/use/helpers";

export default {
  handleErrorRequest(status) {
    switch (status) {
      case 500:
        helpers.errorAlertModal("something went wrong");
        break;
      case 400:
        helpers.errorAlertModal("We can not fetch countries, Try again");
        break;
      case 401:
        helpers.errorAlertModal("You are not allowed to do this operation");
        break;
      case 403:
        helpers.errorAlertModal("You are not allowed to do this operation");
        break;
      case 409:
        helpers.errorAlertModal("You are not allowed to do this operation");
        break;
      case 404:
        helpers.errorAlertModal("Country not found");
        break;
      default:
        throw new Error("Undefined error");
    }
  },

  handleSuccessRequest(response, message) {
    if (response.success) {
      helpers.successAlertModal(message);
    }
  },
};
