import store from "@/store";
import {CommunitiesRequest as communitiesRequest} from "@/services/catalogue/communities/requests";
import helpers from "@/use/helpers";


export const CommunitiesStore = {
    namespaced: true,
    state: {
        communities: [],
        community: null,
        loading: false,
    },

    getters: {
        communities(state) {
            return state.communities
        },

        community(state) {
            return state.community
        },

        loading(state) {
            return state.loading
        },

    },

    mutations: {
        SET_communities: (state, value) => {
            state.communities = value
        },

        SET_community: (state, value) => {
            state.community = value
        },

        PUSH_community: (state, value) => {
            state.communities.unshift(value)
        },

        SET_LOADING: (state, value) => {
            state.loading = value
        },
    },

    actions: {
        getCommunities({commit}, params) {
            commit('SET_LOADING', true)
            communitiesRequest.communities(params)
                .then((response) => {
                    commit('SET_communities', response.data)
                })
                .catch(() => {
                    commit('SET_communities', [])
                    helpers.errorAlertModal('Something went wrong')
                }).finally(() => {
                commit('SET_LOADING', false)
            })
        },

        getCommunity({commit}, id) {
            store.commit('SET_PAGE_LOADING', true)
            communitiesRequest.community(id)
                .then((response) => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_community', response.data)
                })
                .catch(() => {
                    store.commit('SET_PAGE_LOADING', false)
                    commit('SET_community', null)
                    helpers.errorAlertModal('Something went wrong')
                })
        },

        createCommunity({commit, dispatch}, data) {
            commit('SET_LOADING', true)
            communitiesRequest.createCommunity(data.form)
                .then(() => {
                    commit('SET_LOADING', false)
                    dispatch('getCommunities', data?.params)
                    helpers.successAlertModal('Community updated')
                })
                .catch(() => {
                    commit('SET_LOADING', false)
                    commit('SET_community', {})
                    helpers.errorAlertModal('Something went wrong')
                })
        },

        updateCommunity({commit, dispatch}, data) {
            commit('SET_LOADING', true)
            communitiesRequest.updateCommunity(data.form.id, data.form)
                .then((response) => {
                    if (response.success) {
                        commit('SET_LOADING', false)
                        dispatch('getCommunities', data.params)
                        helpers.successAlertModal('Community updated')
                    }
                })
                .catch(() => {
                    commit('SET_LOADING', false)
                    helpers.errorAlertModal('Something went wrong')
                })
        },

        deleteCommunity({commit, dispatch}, data) {
            commit('SET_LOADING', true)
            communitiesRequest.deleteCommunity(data.id)
                .then((response) => {
                    if (response.success) {
                        commit('SET_LOADING', false)
                        helpers.successAlertModal('Community deleted')
                    }
                })
                .catch(() => {
                    commit('SET_LOADING', false)
                    helpers.errorAlertModal('Something went wrong')
                }).finally(() => {
                dispatch('getCommunities', data.params)
            })
        }
    }
}