const BrandListPage = () => import(/* webpackChunkName: "BrandListPage" */ "@/services/brands/pages/BrandListPage")
const SidebarComponent = () => import(/* webpackChunkName: "SidebarComponent" */ "@/components/common/SidebarComponent")

export const BrandsRoutes = [
  {
    path: "/brands",
    name: "BrandListPage",
    components: { default: BrandListPage, sidebar: SidebarComponent },
    meta: {
      authenticated: true,
      PageTitle: process.env.VUE_APP_NAME + " - Brands list",
    },
  }
];