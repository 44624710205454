import Http from "@/axios";

export const SecurityRequest =  {

    //  Get the authenticated user
    getUser(){
        return new Promise(  (resolve, reject) => {
            Http.post('/auth/user', {
                appId: process.env.VUE_APP_ID,
                fields: {
                    clientId: "string",
                    deviceId: "string"
                },
                platform: "DEKSTOP",
                sectionId: "auth_current_user"
            }).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    login(credentials){
        return new Promise(  (resolve, reject) => {
            Http.post('/auth/login', credentials).then((response) => {
                resolve(response.data)
            }).catch((error) => reject(error.response.data))
        })
    },

    logout(){
        return new Promise((resolve, reject) => {
            Http.post('/auth/logout').then((response) => {
                resolve(response.data)
            }).catch( (error) => reject(error.response.data))
        })
    }
}