const NicheListPage = () => import(/* webpackChunkName: "NicheListPage" */ "@/services/niches/pages/NicheListPage")
const SidebarComponent = () => import(/* webpackChunkName: "SidebarComponent" */ "@/components/common/SidebarComponent")

export const NichesRoutes = [
  {
    path: "/niches",
    name: "NicheListPage",
    components: { default: NicheListPage, sidebar: SidebarComponent },
    meta: {
      authenticated: true,
      PageTitle: process.env.VUE_APP_NAME + " - Niches list",
    },
  }
];